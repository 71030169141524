var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-card-body', [_c('b-row', [_c('b-col', [_c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "client",
      "dataset-name": "time-tracker-vacation-requests",
      "hide-custom-filters": true,
      "hide-search-button": false,
      "show-labels": false,
      "compact": false,
      "loaded": _vm.filteringPanel.loaded,
      "filters": _vm.filteringPanel.filters
    },
    on: {
      "change": _vm.onFilteringPanelChange,
      "search": _vm.onFilteringPanelSearch,
      "reset": _vm.onFilteringPanelReset,
      "loaded": _vm.onFilteringPanelLoad
    }
  })], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', [_c('table-custom', {
    ref: "requests",
    attrs: {
      "name": "custom_table_vacation_requests",
      "loading": _vm.isLoading,
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options
    },
    scopedSlots: _vm._u([{
      key: "custom-actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('div', {
          staticClass: "btn-group"
        }, [props.row.Status === 'On Review' && !_vm.isLoading ? _c('button', {
          staticClass: "btn btn-success btn-sm",
          on: {
            "click": function click($event) {
              return _vm.confirmItem(props.row.ID);
            }
          }
        }, [props.row.isLoadingApprove ? _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow"
          }
        }) : _vm._e(), !props.row.isLoadingApprove ? _c('font-awesome-icon', {
          attrs: {
            "icon": "check"
          }
        }) : _vm._e()], 1) : _vm._e(), (props.row.Status === 'On Review' || _vm.showDeclineAction(props.row)) && !_vm.isLoading ? _c('button', {
          staticClass: "btn btn-danger btn-sm",
          on: {
            "click": function click($event) {
              return _vm.declineItem(props.row.ID);
            }
          }
        }, [props.row.isLoadingDecline ? _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow"
          }
        }) : _vm._e(), !props.row.isLoadingDecline ? _c('font-awesome-icon', {
          attrs: {
            "icon": "ban"
          }
        }) : _vm._e()], 1) : _vm._e()])]);
      }
    }])
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }